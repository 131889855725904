@import "@/styles/_variables.scss";
































































































































































































.capital {
    text-transform: uppercase;
}
