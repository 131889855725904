@import "@/styles/_variables.scss";






































































































































































































































































































































































































.trash-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.add-lang-btn {
  padding: 0 20px;
}
.disable {
  pointer-events: none;
}
.close:hover {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.close {
  vertical-align: middle;
  display: flex;
  color: #a8a8a8;
  cursor: pointer;
}
