@import "@/styles/_variables.scss";









































































































































































































































































































































































.selected {
  background-color: var(--app-primary-color);
}

.access {
  padding: 0 10px;
}
