@import "@/styles/_variables.scss";



























































































































































































































































































































.tag-text {
    margin-left: 3px;
}
