@import "@/styles/_variables.scss";






























































































































































































































































































































































































































































































































































.back-link {
  cursor: pointer;
}
.image-tile {
    width: 100%;
    height: 450px;
}
.video-not-ready {
  background: rgba(0, 0, 0, 0.3);
}
.download {
  color: var(--app-primary-color);
}
.disable {
  pointer-events: none;
}

.uk-text-truncate {
  color:var(--app-primary-color);
  cursor:pointer;
}
.uk-text-truncate:hover {
    color:var(--text-color);
}
.edit-media-list {
  padding: 0 10px;
  height: 40px;
}
